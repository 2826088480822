import {
  Anchor,
  Breadcrumbs as MantineBreadcrumbs,
  Container,
  createStyles,
  Group,
  Text,
  useMantineTheme
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import Link from 'next/link'

import { Breadcrumb as BreadcrumbType } from '@/types'

const useStyles = createStyles(theme => ({
  nav: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    padding: theme.spacing.xs,
    paddingLeft: 0,
    paddingRight: 0,
    opacity: 0.9,
    backgroundColor: theme.colors[theme.primaryColor][0],
    backdropFilter: 'blur(10px) saturate(160%) contrast(45%) brightness(140%)',
    WebkitBackdropFilter:
      'blur(10px) saturate(160%) contrast(45%) brightness(140%)'
  }
}))

interface Props {
  breadcrumbs: BreadcrumbType[]
}

export function Breadcrumbs({ breadcrumbs }: Props) {
  // Hooks
  const { classes } = useStyles()
  const theme = useMantineTheme()
  const isSmMd = useMediaQuery(
    `(min-width: ${theme.breakpoints.xs}px) and (max-width: ${theme.breakpoints.md}px)`
  )

  return (
    <nav className={classes.nav}>
      <Container size="xl" py={0} px="xl">
        <MantineBreadcrumbs
          sx={{ flexWrap: 'wrap', justifyContent: isSmMd ? 'center' : 'flex-start' }}>
          {breadcrumbs.map((item, index) => (
            <Group
              key={item.href}
              position="left"
              spacing="xs"
              align="center"
              sx={{ flexWrap: 'nowrap' }}>
              {!!item.icon && item.icon}
              {item.isActive ? (
                <Text size="sm" color="gray.9" weight={700}>
                  {item.title}
                </Text>
              ) : (
                <Anchor
                  size="sm"
                  color="gray.9"
                  href={item.href}
                  component={Link}>
                  {item.title}
                </Anchor>
              )}
            </Group>
          ))}
        </MantineBreadcrumbs>
      </Container>
    </nav>
  )
}
