import { Badge, Box, Button, Divider, Group, Paper, Stack, Text, Title } from '@mantine/core'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

import { CurrencyField } from '@/components/commons/DisplayData'
import { VoucherForm } from '@/containers/forms'
import { useNav } from '@/providers/NavProvider'
import { Basket as BasketType } from '@/types'

interface Props {
  basket: BasketType
}

export default function BasketSummary({ basket }: Props) {
  // Hooks
  const router = useRouter()
  const { setBasketDrawerOpen } = useNav()

  // Constants
  const {
    checkoutPaymentsTotal,
    displayVoucher,
    discountTotal,
    freightTotal,
    futurePaymentsTotal,
    lines,
    numLines,
    subtotal,
    total,
    uid
  } = basket || {}
  const {
    channel: channelSlug,
    store: storeSlug,
    showcase: showcaseSlug,
    collection: collectionSlug
  } = router.query || {}
  const continueBuyingUrl = `/${channelSlug}/${storeSlug}/${showcaseSlug}${
    collectionSlug ? `?collection=${collectionSlug}` : ''
  }`

  return (
    <Paper
      p="sm"
      withBorder
      sx={theme => ({
        position: 'sticky',
        top: 0,
        borderColor: theme.colors[theme.primaryColor]?.[3]
      })}>
      <Title mb="sm" order={5}>
        Resumo
      </Title>
      <Stack spacing="xs">
        <Group position="apart">
          <Text color="dimmed" size="xs">
            ITENS
          </Text>
          <Badge>{numLines}</Badge>
        </Group>
        <Group position="apart">
          <Text color="dimmed" size="xs">
            SUB-TOTAL
          </Text>
          <Text size="sm">
            <CurrencyField value={subtotal} />
          </Text>
        </Group>
        <Group position="apart" hidden={numLines === 0}>
          <Text color="dimmed" size="xs">
            FRETE
          </Text>
          <Text size="sm">
            <CurrencyField value={freightTotal} />
          </Text>
        </Group>
        <Group position="apart">
          <Text color="dimmed" size="xs">
            DESCONTO
          </Text>
          <Text size="sm">
            <CurrencyField value={discountTotal} />
          </Text>
        </Group>
        <Box hidden={futurePaymentsTotal <= 0}>
          <Divider mb="sm" />
          <Group position="apart" hidden={checkoutPaymentsTotal <= 0}>
            <Text color="dimmed" size="xs">
              PAGO AGORA
            </Text>
            <Text size="sm">
              <CurrencyField value={checkoutPaymentsTotal} />
            </Text>
          </Group>
          <Group position="apart" hidden={futurePaymentsTotal <= 0}>
            <Text color="dimmed" size="xs">
              COBRANÇAS FUTURAS
            </Text>
            <Text size="sm">
              <CurrencyField value={futurePaymentsTotal} />
            </Text>
          </Group>
        </Box>
        <Box hidden={!displayVoucher}>
          <Divider mb="sm" />
          <VoucherForm />
        </Box>
        <Divider />
        <Group position="apart">
          <Text color="dimmed" size="xs" weight="bold">
            TOTAL
          </Text>
          <Text size="sm" color="blue.5" weight="bold">
            <CurrencyField value={total} />
          </Text>
        </Group>
        <Button
          component={Link}
          href={`/checkout/${uid}`}
          disabled={Array.isArray(lines) && lines.length === 0}
          onClick={() => setBasketDrawerOpen?.(false)}>
          Avançar
        </Button>
        <Button
          variant="outline"
          component={Link}
          href={continueBuyingUrl}
          hidden={!showcaseSlug}
          onClick={() => setBasketDrawerOpen?.(false)}>
          Continuar Comprando
        </Button>
      </Stack>
    </Paper>
  )
}
