import { Box, Divider, Group, Paper, Stack, Text } from '@mantine/core'
import React from 'react'

import { ComplementValue, CurrencyField } from '@/components/commons/DisplayData'
import { ProductOption as ProductOptionType } from '@/types'

interface Props {
  options: ProductOptionType[]
}

export default function ProductComplements({ options }: Props) {
  return (
    <Paper withBorder>
      <Box p="xs">
        <Text weight="bold" size="sm">Complementos</Text>
      </Box>
      <Stack spacing={0}>
        {options.map(({ additionAmount, name, type, value, uid }) => (
          <React.Fragment key={uid}>
            <Divider />
            <Group p="xs" position="apart">
              <Box>
                <Text size="xs">{name}</Text>
                <ComplementValue type={type} value={value} />
              </Box>
              {additionAmount &&
              additionAmount > 0 && (
                <Text color="blue.5" size="sm" weight="bold">
                  + <CurrencyField value={additionAmount} />
                </Text>
              )}
            </Group>
          </React.Fragment>
        ))}
      </Stack>
    </Paper>
  )
}
