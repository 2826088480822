import { Anchor, Container, Footer as MantineFooter, Grid, Stack, Text } from '@mantine/core'
import Link from 'next/link'
import React, { Fragment } from 'react'

import { MenuContent as MenuContentType, MenuItem as MenuItemType } from '@/types'

interface ItemProps {
  item: MenuItemType
  depth: number
}

function MenuItem({ item, depth }: ItemProps) {
  const maxDepth = 2
  const name =
    depth === 0 ? <Text weight={700}>{item.name}</Text> : <Text size="sm">{item.name}</Text>

  return (
    <>
      {!item.destination?.absoluteUrl ? (
        name
      ) : (
        <Anchor
          color="gray.9"
          href={item.destination?.absoluteUrl}
          target={item.destination?.newWindow ? '_blank' : '_self'}
          component={item.destination?.type !== 'url' ? Link : undefined}>
          {name}
        </Anchor>
      )}
      {!!item.items?.length && depth < maxDepth && (
        <Stack spacing={1} ml={depth > 0 ? 15 : 0}>
          {item.items.map(subitem => (
            <Fragment key={subitem.name}>
              <MenuItem item={subitem} depth={depth + 1} />
            </Fragment>
          ))}
        </Stack>
      )}
    </>
  )
}

interface Props {
  content: MenuContentType
}

export default function Menu({ content }: Props) {
  return (
    <MantineFooter fixed={false} height="auto" py="xl">
      <Container size="xl" px="xl">
        {content.customContent ? (
          <div dangerouslySetInnerHTML={{ __html: content.customContent }}></div>
        ) : (
          <Grid>
            {content.items?.map(item => {
              const columns = content.items ? 12 / content.items.length : 12
              return (
                <Grid.Col key={item.name} xs={columns} sm={columns < 4 ? columns : 4}>
                  <MenuItem item={item} depth={0} />
                </Grid.Col>
              )
            })}
          </Grid>
        )}
      </Container>
    </MantineFooter>
  )
}
