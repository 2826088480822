import { Box, Container, Footer as MantineFooter, Group, Text } from '@mantine/core'
import React from 'react'

import { SocialIcons } from '@/components/widgets'
import { Site as SiteType } from '@/types'

import Menu from '../Menu'

interface Props {
  siteData: SiteType
  footerRef: React.RefObject<HTMLDivElement>
}

export default function Footer({ siteData, footerRef }: Props) {
  return (
    <div ref={footerRef}>
      {!!siteData?.menus?.footer && <Menu content={siteData.menus.footer} />}
      <MantineFooter
        fixed={false}
        height="auto"
        py="xl"
        hidden={!siteData?.links && !siteData?.displaySiteInformation}>
        <Container size="xl">
          <Group position="apart">
            <Box>
              {siteData?.displaySiteInformation && (
                <>
                  <Text weight={700}>{siteData?.seller?.displayName}</Text>
                  {siteData?.description && (
                    <Text size="sm" color="dimmed">
                      {siteData.description}
                    </Text>
                  )}
                  {siteData?.seller?.address && (
                    <Text size="sm" color="dimmed">
                      <strong>Endereço:</strong> {siteData.seller.address}
                    </Text>
                  )}
                  {siteData?.seller?.taxDocument && (
                    <Text size="sm" color="dimmed">
                      <strong>CNPJ:</strong> {siteData.seller.taxDocument}
                    </Text>
                  )}
                </>
              )}
            </Box>
            <SocialIcons links={siteData?.links} variant="light" size="lg" />
          </Group>
        </Container>
      </MantineFooter>
    </div>
  )
}
