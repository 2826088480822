import {
  Avatar,
  createStyles,
  Divider,
  Indicator,
  Navbar,
  NavLink,
  Skeleton,
  Text,
  Title
} from '@mantine/core'
import Link from 'next/link'
import { Fragment } from 'react'

import { useAuth } from '@/providers/AuthProvider'
import { Site as SiteType } from '@/types'

import { menuItems } from './constants'

const useStyles = createStyles(theme => ({
  avatar: { border: `1px solid ${theme.colors.gray[3]}`, borderRadius: '50%' },
  navLink: { borderRadius: theme.radius.sm }
}))

export default function UserNavbar({ siteData }: { siteData: SiteType }) {
  // Hooks
  const { classes } = useStyles()
  const { userData, isValidating } = useAuth() || {}

  // Constants
  const { user } = userData || {}

  return (
    <Navbar sx={{ border: 'none' }}>
      <Navbar.Section>
        <Avatar
          size={80}
          radius={80}
          mx="auto"
          src={user?.picture}
          alt={user?.firstName}
          className={classes.avatar}>
          {isValidating && <Skeleton height={80} circle />}
        </Avatar>
        <Title order={3} align="center" mt="md">
          {user?.firstName}
          {user?.lastName && ` ${user?.lastName}`}
        </Title>
        <Text align="center" color="dimmed" size="sm" hidden={!user?.email}>
          {user?.email}
        </Text>
      </Navbar.Section>
      <Navbar.Section mt="md" p="lg">
        {menuItems(user).map((item, index) => {
          if (item.section) {
            return (
              <Text key={item.label} size="sm" color="dimmed" mb={4} px="sm">
                {item.label}
              </Text>
            )
          }
          if (item.divider) return <Divider key={`divider-${index}`} my="md" />
          return item.active ? (
            <Indicator color="orange" disabled={!item.isStaff} position="top-start">
              <NavLink
                key={item.label}
                label={item.label}
                icon={item.icon?.(20)}
                color={item.color || 'gray.8'}
                component={item.component || Link}
                href={item.href as string}
                description={item.description}
                disabled={item.disabled}
                variant="light"
                rel="noopener noreferrer"
                className={classes.navLink}
                mb={4}
              />
            </Indicator>
          ) : (
            <Fragment key={item.label}></Fragment>
          )
        })}
      </Navbar.Section>
    </Navbar>
  )
}
