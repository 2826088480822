import { Box, Group, MantineNumberSize, ThemeIcon, ThemeIconVariant, Tooltip } from '@mantine/core'
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTwitter,
  IconBrandWhatsapp,
  IconBrandYoutube,
  IconWorld
} from '@tabler/icons'
import Link from 'next/link'

import { SocialLink as SocialLinkType } from '@/types'

interface Props {
  links: SocialLinkType[]
  spacing?: MantineNumberSize
  variant?: ThemeIconVariant
  size?: MantineNumberSize
}

export default function SocialIcons({
  links,
  spacing = 'xs',
  variant = 'light',
  size = 'md'
}: Props) {
  const opts = {
    facebook: {
      icon: <IconBrandFacebook />,
      defaultLabel: 'Facebook'
    },
    twitter: {
      icon: <IconBrandTwitter />,
      defaultLabel: 'Twitter'
    },
    instagram: {
      icon: <IconBrandInstagram />,
      defaultLabel: 'Instagram'
    },
    linkedin: {
      icon: <IconBrandLinkedin />,
      defaultLabel: 'LinkedIn'
    },
    youtube: {
      icon: <IconBrandYoutube />,
      defaultLabel: 'YouTube'
    },
    whatsapp: {
      icon: <IconBrandWhatsapp />,
      defaultLabel: 'WhatsApp'
    },
    other: {
      icon: <IconWorld />,
      defaultLabel: 'Visitar Página'
    }
  }
  return (
    <Group spacing={spacing}>
      {links?.map((link: SocialLinkType) => (
        <Box
          key={link.url}
          component={Link}
          href={link.url}
          rel="noopener noreferrer"
          target="_blank"
          sx={{ textDecoration: 'none' }}>
          <Tooltip label={link.title || opts[link.icon].defaultLabel}>
            <ThemeIcon variant={variant} size={size}>
              {opts[link.icon].icon}
            </ThemeIcon>
          </Tooltip>
        </Box>
      ))}
    </Group>
  )
}
