import {
  Button,
  Drawer,
  DrawerPosition,
  Grid,
  Group,
  LoadingOverlay,
  Modal,
  ScrollArea,
  Text,
  Title,
  useMantineTheme
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'

import { useFetch } from '@/lib/hooks'
import { api, getUrlString, notificationHandler } from '@/lib/utils'
import { useAuth } from '@/providers/AuthProvider'

import BasketLines from './BasketLines'
import BasketSummary from './BasketSummary'

interface Props {
  opened: boolean
  onClose: () => void
  position: DrawerPosition
  handleBasketCount: (count: number) => void
}

export default function BasketDrawer({ opened, onClose, position, handleBasketCount }: Props) {
  // Hooks
  const { isAuthenticated } = useAuth() || {}
  const router = useRouter()
  const viewport = useRef(null)
  const theme = useMantineTheme()
  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`)

  // Constants
  const { site } = router.query || {}
  const siteSlug = getUrlString(site)

  // States
  const [loading, setLoading] = useState<boolean>(false)
  const [modalOpened, setModalOpened] = useState<boolean>(false)
  const [studentUid, setStudentUid] = useState<string>('')
  const [studentName, setStudentName] = useState<string>('')

  // Fetch
  const {
    data: basketData,
    isValidating,
    mutate
  } = useFetch([siteSlug && isAuthenticated ? `/${siteSlug}/basket/` : null])

  // Actions
  const handleCleanBasket = (uid: string, name: string) => {
    setStudentUid(uid)
    setStudentName(name)
    setModalOpened(true)
  }

  const cleanBasket = async () => {
    setLoading(true)
    try {
      const { status } = await api.post(`/${siteSlug}/basket/clean/`, {
        studentUid
      })
      if (status === 200) {
        notificationHandler({ variant: 'success', message: 'O carrinho foi limpado com sucesso.' })
        setModalOpened(false)
        mutate()
      }
    } catch (error) {
      notificationHandler({
        variant: 'error',
        message: 'Ocorreu um erro ao limpar o carrinho. Tente novamente mais tarde. 🤥'
      })
    }
    setLoading(false)
  }

  // Effects
  useEffect(() => {
    handleBasketCount(basketData?.lines?.length || 0)
  }, [basketData?.lines?.length, handleBasketCount])

  if (!isAuthenticated) return null

  return (
    <>
      <Drawer
        opened={opened}
        onClose={onClose}
        padding="lg"
        size={isXs ? 'full' : '70%'}
        title={<Title order={3}>Carrinho</Title>}
        position={position}>
        <ScrollArea
          style={{ height: 'calc(100vh - 100px)' }}
          viewportRef={viewport}
          offsetScrollbars>
          <Grid mr="xs">
            <Grid.Col span={12} lg={8} sx={{ position: 'relative' }}>
              {isValidating ? (
                <LoadingOverlay overlayColor="white" visible />
              ) : (
                <BasketLines
                  basket={basketData}
                  handleCleanBasket={handleCleanBasket}
                  handleMutate={() => mutate()}
                />
              )}
            </Grid.Col>
            <Grid.Col span={12} lg={4}>
              <BasketSummary basket={basketData} />
            </Grid.Col>
          </Grid>
        </ScrollArea>
      </Drawer>

      <Modal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        title={<Text weight="bold">Limpar este carrinho</Text>}
        closeButtonLabel="Fechar"
        withCloseButton
        centered>
        <Text mb="sm">
          Tem certeza que deseja remover todos os itens do carrinho
          {studentName && (
            <>
              {' '}
              do aluno{' '}
              <Text component="span" weight="bold">
                {studentName}
              </Text>
            </>
          )}
          ?
        </Text>
        <Group position="right">
          <Button variant="outline" onClick={() => setModalOpened(false)}>
            Fechar
          </Button>
          <Button loading={loading} onClick={cleanBasket}>
            Confirmar
          </Button>
        </Group>
      </Modal>
    </>
  )
}
