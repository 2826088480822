import { ActionIcon, Button, Group, Input, Loader, Modal, Paper, Text } from '@mantine/core'
import { IconMinus, IconPlus, IconTrash } from '@tabler/icons'
import { useRouter } from 'next/router'
import { FormEvent, useState } from 'react'

import { api, getUrlString, notificationHandler } from '@/lib/utils'

interface Props {
  lineUid: string
  basketQuantity: number
  minimalSellingQuantity?: number
  type: string
  handleMutate: () => void
}

export default function QuantityButton({
  lineUid,
  basketQuantity,
  minimalSellingQuantity = 1,
  type,
  handleMutate
}: Props) {
  // Hooks
  const router = useRouter()

  // Constants
  const color = 'blue.5'
  const { site } = router.query || {}
  const siteSlug = getUrlString(site)

  // States
  const [loading, setLoading] = useState<boolean>(false)
  const [opened, setOpened] = useState<boolean>(false)
  const [quantity, setQuantity] = useState<number>(basketQuantity)

  // Actions
  const handleFocus = (event: FormEvent<HTMLInputElement>) => event.currentTarget.select()

  const changeBasketLine = async (newQuantity: number) => {
    setLoading(true)
    try {
      const { status } = await api.patch(`/${siteSlug}/basket/lines/${lineUid}/`, {
        quantity: newQuantity
      })
      if (status === 200) {
        setQuantity(newQuantity)
        handleMutate()
      } else {
        notificationHandler({
          variant: 'error',
          message: 'Ocorreu um erro ao atualizar o produto. Tente novamente mais tarde. 🤥',
          color: 'red'
        })
      }
    } catch (error: any) {
      const [errors] = error?.response?.data?.nonFieldErrors || []
      notificationHandler({
        variant: 'error',
        message: errors || 'Ocorreu um erro ao atualizar o produto. Tente novamente mais tarde. 🤥',
        color: 'red'
      })
    }
    setLoading(false)
  }

  const deleteBasketLine = async () => {
    try {
      const { status } = await api.delete(`/${siteSlug}/basket/lines/${lineUid}/`)
      if (status === 204) {
        setOpened(false)
        handleMutate()
      }
    } catch (error: any) {
      const [errors] = error?.response?.data?.nonFieldErrors || []
      notificationHandler({
        variant: 'error',
        message:
          errors ||
          'Ocorreu um erro ao remover o produto do carrinho. Tente novamente mais tarde. 🤥'
      })
    }
  }

  const handleQuantity = async (newQuantity: number) => {
    if (
      basketQuantity !== newQuantity &&
      (newQuantity >= minimalSellingQuantity || newQuantity === 0)
    ) {
      if (Number(newQuantity) === 0) {
        setOpened(true)
      } else {
        changeBasketLine(newQuantity)
      }
    }
  }

  const handleChangeQuantity = ({ currentTarget: { value } }: FormEvent<HTMLInputElement>) => {
    if (Number(value) >= minimalSellingQuantity || Number(value) === 0) {
      setQuantity(Number(value))
    } else {
      notificationHandler({
        variant: 'error',
        message: `Quantidade mínima deve ser ${minimalSellingQuantity}`
      })
    }
  }

  const handleBlurQuantity = ({ currentTarget: { value } }: FormEvent<HTMLInputElement>) => {
    handleQuantity(Number(value))
  }

  return (
    <>
      <Paper withBorder>
        <Group spacing={0} position="center">
          <ActionIcon color={color} disabled={loading} onClick={() => handleQuantity(quantity - 1)}>
            {(minimalSellingQuantity && quantity > minimalSellingQuantity) ||
            (!minimalSellingQuantity && quantity > 1) ? (
                  <IconMinus />
                ) : (
                  <IconTrash />
                )}
          </ActionIcon>
          {loading ? (
            <Loader size="sm" my="xs" mx={5} />
          ) : (
            <Input
              name="quantity"
              value={quantity}
              disabled={type !== 'order' || (type === 'order' && loading)}
              onChange={handleChangeQuantity}
              onBlur={handleBlurQuantity}
              variant="unstyled"
              onFocus={handleFocus}
              sx={{ textAlignLast: 'center', width: '30px' }}
            />
          )}
          <ActionIcon
            color={color}
            disabled={type !== 'order' || (type === 'order' && loading)}
            onClick={() => handleQuantity(quantity + 1)}>
            <IconPlus />
          </ActionIcon>
        </Group>
      </Paper>

      <Modal
        centered
        title={<Text weight="bold">Remover produto</Text>}
        opened={opened}
        onClose={() => setOpened(false)}>
        <Text mb="sm">Deseja remover o produto do carrinho?</Text>
        <Group position="right">
          <Button variant="outline" onClick={() => setOpened(false)}>
            Cancelar
          </Button>
          <Button color="red" onClick={deleteBasketLine}>
            Remover
          </Button>
        </Group>
      </Modal>
    </>
  )
}
